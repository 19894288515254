@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  @apply font-inter;
  color: #333;
  background-color: #fafbff;
}
* {
  padding: 0;
  margin: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
}

input,
textarea {
  font-family: "Inter", "sans-serif";
}

/* p {
    color: #4F4F4F;
} */
.forgot-password {
  color: #161941;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.transition-transform {
  transition-property: transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.notification-tab > .active {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(59 130 246 / var(--tw-border-opacity));
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.notification-tab > .active:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.order-tab > .active {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.order-tab > .active:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.before\:mr-3::before {
  content: var(--tw-content);
  margin-right: 0.75rem;
}

.before\:h-1::before {
  content: var(--tw-content);
  height: 0.25rem;
}

.before\:w-1::before {
  content: var(--tw-content);
  width: 0.25rem;
}

.before\:rounded-full::before {
  content: var(--tw-content);
  border-radius: 9999px;
}

.before\:bg-gray-300::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity));
}

.hover\:bg-gray-50:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

.hover\:bg-gray-950:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.hover\:text-blue-500:hover {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.hover\:text-blue-600:hover {
  --tw-text-opacity: 1;
  color: rgb(37 99 235 / var(--tw-text-opacity));
}

.hover\:text-gray-100:hover {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.hover\:text-gray-600:hover {
  --tw-text-opacity: 1;
  color: rgb(75 85 99 / var(--tw-text-opacity));
}

.focus\:border-blue-500:focus {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.group:hover .group-hover\:text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
}

.group.selected .group-\[\.selected\]\:block {
  display: block;
}

.group.selected .group-\[\.selected\]\:rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.group.active .group-\[\.active\]\:bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.group.selected .group-\[\.selected\]\:bg-gray-950 {
  --tw-bg-opacity: 1;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity));
}

.group.active .group-\[\.active\]\:text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.group.selected .group-\[\.selected\]\:text-gray-100 {
  --tw-text-opacity: 1;
  color: rgb(243 244 246 / var(--tw-text-opacity));
}

.active-menu {
  background-color: #161941;
  color: #fff;
}
.active-menu img {
  filter: brightness(0) invert(1);
}

.dropline::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #161941;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.dropdown {
  position: relative;
  margin-bottom: 20px;
}

.dropdown .dropdown-list {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-height: 223px;
  overflow-y: auto;
  background: #fff;
  color: #000000;
  /* display: none; */
  z-index: 10;
}

.dropdown .dropdown-label {
  display: block;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  position: relative;
}

.dropdown .dropdown-label:before {
  content: "";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.25s;
  transform-origin: center center;
}

.dropdown.open .dropdown-list {
  display: block;
}

.dropdown.open .dropdown-label:before {
  transform: translateY(-50%) rotate(-180deg);
}

.checkbox {
  margin-bottom: 20px;
}

.checkbox:last-child {
  margin-bottom: 0;
}

/* .checkbox .checkbox-custom {
  display: none;
} */

.checkbox .checkbox-custom-label {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
}

/* .checkbox .checkbox-custom + .checkbox-custom-label:before {
  content: "";
  background: transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  text-align: center;
  width: 12px;
  height: 12px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  margin-top: -2px;
} */

.checkbox .checkbox-custom-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 16px;
  font-size: 16px;
  margin-right: 0;
  margin-left: 10px;
  color: black;
}

.upload_loader {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #ffffff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

.resend_loader {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #161941;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  animation: spin 1s linear infinite;
}

.delete_cart_loader {
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-left-color: #828282;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
.cursor-pointer {
  cursor: pointer;
}

.pagination .active > .page-link {
  background-color: #161941 !important;
  color: #fff !important;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pagination .page-link {
  border: none !important;
  background-color: transparent !important;
  color: #161941 !important;
  padding: 0 !important;
  font-size: 16px !important;
}
.pagination {
  gap: 15px;
}

.chip-tab {
  height: 70% !important;
  border-radius: 10px;
  padding: 0 10px;
}

.dropdown .dropdown-label_one {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  /* line-height: 50px; */
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 40px 0 20px;
  cursor: pointer;
  position: relative;
}

.dropdown .dropdown-label_one:before {
  content: "▼";
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  transition: transform 0.25s;
  transform-origin: center center;
}
.react-time-picker__wrapper {
  border: none !important;
  padding-right: 10px;
}

.css-13cymwt-control:focus,
.css-t3ipsp-control:focus,
.css-t3ipsp-control:visited,
.css-13cymwt-control:visited {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  border-color: white !important;
}
.css-13cymwt-control:hover,
.css-t3ipsp-control:hover {
  border-color: white !important;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  min-height: 58px !important;
  border-style: none !important;
  height: 55px !important;
  border: none !important;
  font-size: 16px !important;
}
/* Add this CSS to your stylesheet or within a <style> tag */
.gm-ui-hover-effect:hover {
  display: none !important;
}

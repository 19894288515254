@media (min-width: 768px) {
  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:hidden {
    display: none;
  }

  .md\:w-\[calc\(100\%-256px\)\] {
    width: calc(100% - 256px);
  }
  .main.active {
    margin-left: 0px;
    width: 100%;
  }
  .dropdown .dropdown-label {
    font-size: 16px;
  }
}
@media screen and (max-width: 1023px) {
  .commonpage.menu-active .main-content {
    margin-left: 0;
    width: 100%;
  }
  .react-tel-input input {
    height: 50px;
  }
  .commonpage.menu-active .sidebar-menu {
    width: 100px;
}
.css-13cymwt-control, .css-t3ipsp-control {
  min-height: 50px !important;
  height: 50px !important;
}
}

@media screen and (max-width: 768px) {
  .dropdown .dropdown-label,
  .dropdown-label {
    height: 50px !important;
    line-height: 50px;
    font-size: 16px;
  }
}
@media screen and (max-width: 575px) {
  .custom-line::before {
    top: 12px;
    width: 92%;
  }
  .dropdown .dropdown-label,
  .dropdown-label {
    padding: 0 0 0 50px;
    font-size: 14px;
    height: 45px !important;
    line-height: 43px;
  }
  input {
    border-width: 1px;
  }
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .dropline::before {
    height: 4px;
  }
  .setting-fish-inrest .dropdown-label_one {
    height: 45px;
    font-size: 14px;
    padding: 0 12px;
  }
  .react-tel-input input {
    height: 45px;
  }
  .role-select {
    padding-left: 50px !important;
  }
  .css-13cymwt-control, .css-t3ipsp-control {
    min-height: 45px !important;
    height: 45px !important;
  }
}
